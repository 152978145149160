import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import GQLCodeSnippet, { GQLOpen, GQLClose, GQLLine, GQLTab, GQLName, GQLKeyword, GQLAttribute, GQLOperator, GQLComment, GQLLink } from "../../components/GQLCodeSnippet/GQLCodeSnippet";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Batch input for location creation/modification.`}</p>
    <GQLCodeSnippet mdxType="GQLCodeSnippet">
      <GQLLine mdxType="GQLLine"><GQLKeyword mdxType="GQLKeyword">type</GQLKeyword> BatchLocationInput <GQLOpen mdxType="GQLOpen" /></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Name of location.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Must not be empty, must be unique and maximum allowed length is 60 characters.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">name</GQLName>: <GQLLink href="/ecdn-api-scalars/String" mdxType="GQLLink">String</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># ID of parent region/location.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">parentID</GQLName>: <GQLLink href="/ecdn-api-scalars/ID" mdxType="GQLLink">ID</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Restriction for client connections. Omit if there aren't any restrictions.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">clientRestriction</GQLName>: <GQLLink href="/ecdn-api-input-objects/ClientRestrictionInput" mdxType="GQLLink">ClientRestrictionInput</GQLLink></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Whether clients can connect to servers in this location or not. Defaults to true.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">clientConnectionsEnabled</GQLName>: <GQLLink href="/ecdn-api-scalars/Boolean" mdxType="GQLLink">Boolean</GQLLink></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Whether clients can fall back to external CDNs or not. Defaults to true.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">cdnFallbackEnabled</GQLName>: <GQLLink href="/ecdn-api-scalars/Boolean" mdxType="GQLLink">Boolean</GQLLink></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># External IP ranges of location. Omit if ranges are inherited.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Cannot be filled if inheritExternalRanges is set to true.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">externalRanges</GQLName>: [<GQLLink href="/ecdn-api-scalars/IPv4Range" mdxType="GQLLink">IPv4Range</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator>]</GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Whether external ranges are inherited from parent location.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Defaults to false, must have a parent location if it's set to true.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Cannot be true if externalRanges field is filled as well.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">inheritExternalRanges</GQLName>: <GQLLink href="/ecdn-api-scalars/Boolean" mdxType="GQLLink">Boolean</GQLLink></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
      <GQLLine mdxType="GQLLine"><GQLClose mdxType="GQLClose" /></GQLLine>
    </GQLCodeSnippet>
    <h2>{`Fields`}</h2>
    <h3>{`name: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/String"
      }}>{`String`}</a>{`!`}</h3>
    <p>{`Name of location.
Must not be empty, must be unique and maximum allowed length is 60 characters.`}</p>
    <h3>{`parentID: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/ID"
      }}>{`ID`}</a>{`!`}</h3>
    <p>{`ID of parent region/location.`}</p>
    <h3>{`clientRestriction: `}<a parentName="h3" {...{
        "href": "/ecdn-api-input-objects/ClientRestrictionInput"
      }}>{`ClientRestrictionInput`}</a></h3>
    <p>{`Restriction for client connections. Omit if there aren’t any restrictions.`}</p>
    <h3>{`clientConnectionsEnabled: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/Boolean"
      }}>{`Boolean`}</a></h3>
    <p>{`Whether clients can connect to servers in this location or not. Defaults to true.`}</p>
    <h3>{`cdnFallbackEnabled: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/Boolean"
      }}>{`Boolean`}</a></h3>
    <p>{`Whether clients can fall back to external CDNs or not. Defaults to true.`}</p>
    <h3>{`externalRanges: [`}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/IPv4Range"
      }}>{`IPv4Range`}</a>{`!]`}</h3>
    <p>{`External IP ranges of location. Omit if ranges are inherited.
Cannot be filled if inheritExternalRanges is set to true.`}</p>
    <h3>{`inheritExternalRanges: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/Boolean"
      }}>{`Boolean`}</a></h3>
    <p>{`Whether external ranges are inherited from parent location.
Defaults to false, must have a parent location if it’s set to true.
Cannot be true if externalRanges field is filled as well.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      